
:root {
  --bgcolor:#FEFCF5;
  --orange: #FF8150;
  --white: #ffffff;
  --black: #000000;
  --bluedark:  #203B4C;
  --faintgray:#E9EBED;
  --gray:#F4F5F6;
  --font20: 20px;
  --font16: 16px;
  --font12: 12px;
  --lightgray: #C4C4C4;
  --bordergray: #C8CED1;
  --radius10: 10px;
  --radius100: 100px;
  --radiusfull: 100%;
  --padding20: 20px;
  --width80: 80px;
  --width50: 50%;
  --width60: 60%;
  --width96: 96%;
  --width100: 100%;
  --fivepx: 5px;
  --tenpx: 10px;
  --fiftynpx: 15px;
  --twentypx: 20px;
  --thirtypx: 30px;
  --fortypx: 40px;
  --fiftypx: 50px;
}

body{
  background-color: var(--bgcolor);
  font-size: var(--font16);
  color:var(--black);
  line-height: 21px;
  padding: 0px;
  margin: 0px;
  font-family: 'dm_sansregular';
}
p,h1{
  margin: 0px;
}
div {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
.border1px{
  border:1px solid var(--bordergray);
}
.radius10px{
  border-radius:var(--radius10);
}
.font-bold{
  font-family: 'dm_sansbold';
}
.clear-gray{
  color:#637682;
}
.font-regular{
  font-family: 'dm_sansregular';
}
.body-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-x: hidden;
}
.mb5{
  margin-bottom: var(--fivepx);
}
.mb10{
  margin-bottom: var(--tenpx);
}
.mb15{
  margin-bottom: var(--fiftynpx);
}
.mb20{
  margin-bottom: var(--twentypx);
}
.mt50{
  margin-top: var(--fiftypx);
}
.mt25{
  margin-top: 25px;
}
.mt30{
  margin-top: var(--thirtypx);
}
.font12{
  font-size: var(--font12);
}
.donate-text{
  font-family: 'dm_sansregular';
  font-size: 16px;
  line-height: 21px;
  color: var(--black);
}
.screen-wrapper{
  width: 100%;
  max-width: 450px;
  border-radius: var(--radius10);
  border:1px solid var(--black);
  background: var(--white);
  padding: var(--padding20);
  height:550px;
  max-height: 550px;
  overflow-y: auto;
  position: relative;
}
.head-title{
  width: 100%;
  clear: both;
}
.head-title h1{
  font-size: var(--font20);
  line-height: 26px;
  font-family: 'dm_sansbold';
}
.p20-lr{
  padding:0px var(--padding20);
}
.p20-tblr{
  padding:var(--tenpx);
}
.p40-tb{
  padding:var(--fortypx) 0px;
}
.flex-wrap{
  display: flex;
  column-gap: 10px;
}
.flex-dire{
  flex-direction: column;
}
.flex-display{
  display: flex;
}
.justify-content{
  justify-content:center;
}
.flex-gap-10{
  gap: var(--tenpx);
}
.flex-bet{
  justify-content: space-between;
}
.flex-grow{
  flex-grow: 1;
}
.field-label{
  display: block;
  font-size: var(--font12);
  
}
.input-field{
  border: 1px solid var(--bordergray);
  border-radius: var(--radius10);
  width: 100%;
  padding: 15px 10px;
  font-size: var(--font16);
  color: var(--black);
  box-sizing: border-box;
}
input.input-field::placeholder,textarea.input-field::placeholder {
  color: #C4C4C4;
  font-size:var(--font16);
  font-family: 'dm_sansregular';
}
img.App-logo {
  min-width: 130px !important;
  width: 100%;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--black);
  text-align: center;
  white-space: nowrap;
  background-color: var(--gray);
}
textarea.input-field {
  height: 100px !important;
  overflow-y: auto;
  width: 100% !important;
}
span.is-tab-active:before {
  filter: invert(1);
}
.width50{
  width: var(--width50);
}
.width100{
  width: var(--width100);
}
.height100{
  height: var(--width100);
}
.width96{
  width: var(--width96);
}
.width60{
  width: var(--width60);
}
.opacity-0{
  opacity: 0; height:10px;
}
.showError{
  border: 1px solid red;
}
.col{
  -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}
/*nav bar */ 
.cTnFmb {
  background: none !important;
  height: auto !important;
  display: flex;
  -webkit-box-pack: justify;
  padding: 0 !important;
  z-index: 12;
  margin: 0 !important;
}
.top-menu .sc-hLseeU{
  position: relative;
}
.top-menu span::before {
  position: absolute;
  content: '>';
  /* background-image: url(http://localhost:3000/static/media/right-arrow.869d153800a673e2f9cb.svg); */
  width: 11px;
  /* height: 100%; */
  top: 50%;
  right: 0;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  background-position: center;
  font-size: 16px;
  color: #C4C4C4;
  font-weight: 400;
}

.sc-hLseeU.tItsp a:nth-child(1) {
  padding-left: 0px;
}
.top-menu {
  margin: 10px 0px 25px;
}
.top-menu span{
  text-decoration: none;
  color: #C4C4C4;
  font-family: 'dm_sansregular';
  position: relative;
  width: 97px !important;
  cursor: pointer;
}
.top-menu span.active{
  color:var(--orange) !important;
  font-weight: 700;
}
.top-menu span:last-child::before{
display: none;
}
.text-capital{
  text-transform: capitalize;
}
.donation-info h2{
  font-size: var(--font16);
}
.big-btn{
  max-width: 100% !important;
}

/* user-pic */
.user-pic{
  width: var(--fiftypx);
  height: var(--fiftypx);
  border-radius: var(--radius10);
  overflow: hidden;
  background-image: url(../public/images/user-pic.svg);
  background-repeat:no-repeat;
  background-position:center;
  background-size: contain;

}
.thankyou-up{
  background-image: url(../public/images/user-pic.svg);
  background-repeat:no-repeat;
  background-position:center;
  background-size: contain;
  height: 80px;
  width: 80px;
}
.user-pic img{
  height:100%;
  width: 100%;
  object-fit: cover;
}

/* divider */
.divider{
  width: 100%;
  height:1px;
  border-top:1px solid var(--faintgray);
  clear: both;
}

/* org-btn */
.org-btn{
  background-color: var(--orange);
  color: var(--white);
  font-size: var(--font16);
  display:block;
  max-width: 120px;
  width:100%;
  height:auto;
  min-height: 50px;
  cursor: pointer;
  text-align: center;
  border-radius:var(--radius100);
  border:none;
  text-transform: capitalize;
  padding: 15px 25px;
}

/* switch btn */ 
.switch {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 20px;
  background: #dfd9ea;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
}
.switch::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background: #fafafa;
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.switch:active::before {
  box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);
}
input:checked + .switch {
  background: var(--orange);
}
input:checked + .switch::before {
  left: 22px;
  background: var(--white);
}
input:checked + .switch:active::before {
  box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);
}
.border-none{
  border: none;
}
.border-radius-none{
  border-radius: 0px;
}
.overflow-hidden{
  overflow: hidden;
}
.text-right{
  text-align:right;
}

/* otr-btn */
.otr-btn{
  border: 1px solid var(--bordergray);
  background: none;
  color:var(--black);
  font-size: var(--font16);
}

.opacity-0 {
  opacity: 0;
  max-height: 1px;
  height: 100%;
}

.height37{
  height: 37px;
  padding: 5px 10px;
}

/* select button */ 
.selection{
  width:100%;
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
  overflow: hidden;
}
.selection label{
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  color:var(--black);
  font-size: var(--font20);
  min-height: 60px;
  cursor: pointer;
}
.selection-method {}
.selection-method label {
  height: 48px;
    text-align: left;
    line-height: 15px;
    min-height: auto;
    font-size: 12px !important;
    justify-content: flex-start;
}
.info-bg{
  background-image: url(../public/images/info.svg);
  width:16px;
  height:16px;
  background-repeat:no-repeat;
  background-position:center;
  margin-top: 2px;
}
.app-logo-bg{
  background-image: url(../public/images/logo.svg);
  width:130px;
  height:50px;
  background-repeat:no-repeat;
  background-position:center;
  background-size: contain;
}
.selection label:hover {
  background-color: var(--orange);
}

.selection input[type=radio] {
  display: none;
}

.selection input[type=radio]:checked ~ label {
  background-color: var(--orange);
  color: var(--white);
}
.selection input[type=radio]:checked ~ label img{
  filter: invert(1);
}

.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}

.align-center{
  align-items: center !important;
}
.align-left{
  align-items: flex-start !important;
}
.min-height80{
  min-height: 80px !important;
}
.thankyou img{
  max-width:var(--width80);
}
.thankyou h2,.thankyou p{
  font-size: var(--font16);
  line-height: 21px;
}
.thankyou > div{
  color: var(--bluedark);
  font-size: var(--thirtypx);
  margin: 10px 0px;
}
.thankyou h2{
  margin-top: 0px;
}
.thankyou span,.dedect{
  color: #637682;
  font-size: var(--font12);
}
.thankyou-bg{
  position: relative;
}
.thankyou-bg::after{
  content: '';
  background-image: url(../public/images/thankyoubg.svg);
  width:var(--width100);
  position: absolute;
  top: -50px;
  left:0px;
  background-repeat: no-repeat;
  background-position: center;
  height:100%;
  max-height: 220px;
  pointer-events: none;
}
.mx-auto{
  margin:0px auto;
}
.font-12{
  font-size: var(--font12);
}
.org-btn-dis:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@media screen and (max-width: 768px){
  .tItsp {
      display: flex !important;
  }
  .body-wrapper {
    margin: 0px 10px;
  }

}

@media (max-width: 450px) { 
  .top-menu span::before {
    right: -5px;
  }
  .top-menu span{
    width: 90px !important;
  }
  .top-menu span {
    font-size: 15px;
}
}

@media (max-width: 380px) { 
  .top-menu span{
    font-size: 14px;
  }
  .top-menu span{
    width: 80px !important;
  }
  
  .top-menu .sc-hLseeU a::before {
    width: 9px;
  }
  .body-wrapper {
    margin: 0px 5px;
  }
  .top-menu span::before {
    transform: translate(-50%, -52%);
  }
}
@media (max-width: 350px) {
  .top-menu span {
    font-size: 13px;
  }
  .top-menu span::before{
    font-size: 15px;
  }
}
.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #FF8150 transparent #FF8150 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.is-tab-active { color: var(--black) !important;}
